import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { WritingInfo } from "../helpers/WritingInfo"; // Adjust the import path as needed
import "../styles/WritingDetail.css"; // Import the CSS file for styling
import background from "../assets/Glacier_National.jpg"; // Adjust the import path as needed

function WritingDetail() {
  const { name } = useParams();
  const navigate = useNavigate();
  const writing = WritingInfo.find((w) => w.name === name);

  useEffect(() => {
    // Prevent page scrolling
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable page scrolling when component unmounts
      document.body.style.overflow = "auto";
    };
  }, []);

  if (!writing) {
    return <div>Writing not found</div>;
  }

  return (
    <div
      className="writing-detail"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="writing-detail-content">
        <div className="header">
          <h1 className={writing.type === "poems" ? "poem-title" : ""}>
            {writing.name}
          </h1>
          <hr className="poem-divider" />
          {writing.type === "poems" && (
            <h2 className="poem-genre">{writing.genre}</h2>
          )}
          <div className="header-bottom">
            <button className="back-button" onClick={() => navigate(-1)}>
              ← Back
            </button>
            <p className="published-date">
              <strong>Published on:</strong> {writing.date_published}
            </p>
          </div>
        </div>
        <div className="writing-content">
          {writing.type === "poems"
            ? writing.paragraphs.map((stanza, index) => (
                <div key={index} className="stanza">
                  {stanza.map((line, lineIndex) => (
                    <p key={lineIndex} className="poem-line">
                      {line}
                    </p>
                  ))}
                </div>
              ))
            : Object.values(writing.paragraphs).map((paragraph, index) => (
                <ReactMarkdown key={index}>{paragraph}</ReactMarkdown>
              ))}
        </div>
        {writing.references && (
          <div className="writing-references">
            <h2>References</h2>
            <ul>
              {writing.references.map((ref, index) => (
                <li key={index}>{ref}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default WritingDetail;
