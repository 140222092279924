import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar"; // Adjust the import path as needed
import "../styles/writings.css";
import background from "../assets/Glacier_Beach.jpg";
import { WritingInfo } from "../helpers/WritingInfo"; // Adjust the import path as needed

function Writings() {
  const [currentView, setCurrentView] = useState("main");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentView("category");
  };

  const handleBackClick = () => {
    setCurrentView("main");
  };

  const filteredWritings = WritingInfo.filter(
    (writing) => writing.type === selectedCategory.toLowerCase()
  );

  return (
    <div className="writings" style={{ backgroundImage: `url(${background})` }}>
      <Navbar />
      <div className="content">
        <div
          className={`translucentBox ${
            currentView === "category" ? "fade-in" : "fade-out"
          }`}
        >
          {currentView === "main" ? (
            <>
              <h1 className="writingTitle">Writings</h1>
              <div className="subtitle">
                <hr className="separator" />
                <div className="categories">
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Movies")}
                  >
                    <h3 className="categoryName">Movies</h3>
                  </div>
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Personal Writings")}
                  >
                    <h3 className="categoryName">Personal Writings</h3>
                  </div>
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Poems")}
                  >
                    <h3 className="categoryName">Poems</h3>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="header">
                <div className="backButton" onClick={handleBackClick}>
                  ← Back
                </div>
                <h1 className="writingTitle">{selectedCategory}</h1>
              </div>
              <div className="writingsList">
                {filteredWritings.map((writing, index) => (
                  <Link
                    to={`/writing/${writing.name}`}
                    key={index}
                    className="writingModal"
                  >
                    <h3 className="writingTitle">{writing.name}</h3>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Writings;
