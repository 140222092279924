import logo from '../assets/PF.png'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'

function Navbar() {
    return (
        <div className='navbar'>
            <div className='logo'>
                <Link to="/"> <img src={logo} /> </Link>
            </div>
        </div>
    )
}

export default Navbar
