import image from "../assets/PF.png";

export const WritingInfo = [
  {
    name: "Doubts",
    type: "poems",
    genre: "haiku",
    image: image,
    date_published: "10/1/2024",
    paragraphs: [
      [
        "Arduous, endless",
        "Clouding judgement, striking fear",
        "Racing thoughts, ever near",
      ],
    ],
  },
  {
    name: "Guilt",
    type: "poems",
    genre: "haiku",
    image: image,
    date_published: "10/1/2024",
    paragraphs: [
      [
        "Blood on all your hands",
        "Who am I to point at you",
        "With crimson stained clothes",
      ],
    ],
  },
  {
    name: "A Love So Pure",
    type: "poems",
    genre: "haiku",
    image: image,
    date_published: "10/1/2024",
    paragraphs: [
      [
        "He bore sin and shame",
        "A guiltless charged as guilty",
        "Love not meant for me",
      ],
    ],
  },
  {
    name: "Apathetic Resignation",
    type: "poems",
    genre: "Villanelle",
    image: image,
    date_published: "10/1/2024",
    paragraphs: [
      [
        "Here I go, I submit my resignation",
        "Can I not escape the shadows of the past?",
        "How could I not avoid this temptation?",
      ],
      [
        "For I had my expectation",
        "To help those that carry their own fateful cast",
        "Looking out for them is my reputation",
      ],
      [
        "I see myself in this situation",
        "Once again, I ask, “how long does this last?”",
        "Watching the things I love in degradation",
      ],
      [
        "I pour my heart into the younger congregation",
        "For those up above to blast",
        "And I have to deal with the abandonation",
      ],
      [
        "To see this sudden deep connection",
        "Go so well, yet halt so fast",
        "I watch my heart in devastation",
      ],
      [
        "Tears roll down in frustration",
        "What do I do to make the fleeting last?",
        "Here I go, I submit my apathetic resignation",
        "How could I not avoid this temptation?",
      ],
    ],
  },
  {
    name: "Pride, Complacency, and Lack of Contentment",
    type: "personal writings",
    image: image,
    date_published: "1/15/2024",
    paragraphs: {
      1: "I think it is only fitting to talk about the grandiose idea of pride and the endless pursuit to avoid complacency. It is important I define pride as two quotes from the ideas of C.S. Lewis. “Pride gets no pleasure out of having something, only out of having more of it than the next man.” and “Pride is a spiritual cancer: it eats up the very possibility of love, or contentment, or even common sense.” Quite clearly… Pride is a weed… THE SPIRITUAL WEED. It infests the good and the healthy. It has to thrive off of the healthy because that is the ideal environment. It gets in the nooks and crannies and spreads till there is no more room. For example a social worker helping at a successful clinic is more likely to be filled with conceit than a worker that barely has people in need of help at a desolate clinic. It's easy for the social worker at the busy clinic to say, “wow look at the work I have been doing.” The thought rushes to one’s head and soon the pursuit to continue one’s work is motivated by a self-righteous contempt. Anything or anyone that doesn’t acknowledge the blessed acts become a hindrance. There is then a strive to do better because “one is not doing enough” to be recognized. There lies the snare. The competition against oneself and against others is off to the races. In that we have stage 4 spiritual cancer. We begin to lose the ability to love the people we are around. We become discontent where we are at and ignore the blessings given and worked for. The ignorance that has brewed in us becomes an ever increasing loss of common sense.",
      2: "So how does complacency fit into all this? I believe that once someone becomes prideful in their competitiveness they can tell themselves a lie. A lie that states, “I am complacent and need to do better.” Which, in and of itself, is not inherently bad, but becomes a dreadful issue when that phrase is used to justify the sly, prideful pursuits that undermine contentment and the ability to truly love. In fact, it only adds fuel to the fire. Do not get me wrong, as being complacent in one’s middling faith and life situation CAN be incredibly wrong and detrimental. There is a good, healthy, and Christian like way to avoid complacency, but that revolves around becoming more Christ-like and who God wants us to be and doing it all for the right reasons. Once one tells themself, “I am being complacent” and tries to improve themselves with the wrong motivation then lies the issue. See contentment is being able to look at one’s current circumstances and be grateful. To truly be grateful one must understand the blessings one has and the endless blessings one comes nowhere near deserving. To “hate” complacency is understandable. First ask yourself, do you hate that you are being complacent or do you just dislike your current circumstances. If you dislike your current circumstances is that because you have lost your ability to be content? Are you jealous of someone else’s position? Do you believe you deserve better…? It is a blurry line of why you dislike your current state of being. Either it is because of your true desire to go through sanctification and to be healthier, or it is because you are discontent in your current circumstances, have lost the ability of common sense, gained a monstrous ego, or jealousy has overtaken you.",
    },
  },
  {
    name: "Control",
    image: image,
    type: "personal writings",
    date_published: "2/4/2024",
    paragraphs: {
      1: "The art of control is quite demanding, see it is ironic because the need/desire to control situations makes one a slave to it. Quite literally, the feeling to be in complete agency out of fear, avoidance of being hurt, jealousy, righteous anger doesn’t really matter because control is wanting to be the one with power. Control is a desire to be chief of the decision making process. To have all actions flow from oneself. An unhealthy level of craving control is becoming the very thing you sought/seek to rid of. Funny isn't it?  Now, I understand as it is incredibly easy to have that power hungry and/or fear avoidant desire. To an extent, dare I say to a substantial extent, control is actionable, control is deceptive, control is artistic freedom, control is self serving, control is comfortable. What do I mean by these all?",
      2: "One, control is actionable. Controlling is easy to do. It is as simple as a man wanting relaxation so he turns on a sports game and grabs some chips, or that of someone wanting to learn about computers so they watch youtubers and experts to get an idea. One can control how people look at them by wearing specific clothes. Saying simple phrases to appear one way, or making decisions that convince people who you are. All of, in some sense, can be good things. The point being, control is actionable. It is practical at its very foundation.",
      3: "Two, control is artistic freedom. Control gives the individual or situation full creative liberty, like that of an artist. Theoretically, complete control says, “I can do whatever I want without repercussions” and the more control one has the more one can theoretically get away with. There is a reason that people love being creative in the arts because it is freeing. Having control allows one to be more free with their choices.",
      4: "Three, Control is comfortable. When you have more control, choice becomes easier. It is easier to choose a college when you have all the money and there is little chance you flunk out your major. There is no risk. There is less weight to the tough decision. The more you know about said situations allows you to be more in control of the decision you make. I am deviating a little bit here as obviously there are so many external factors. The point I am getting at is that If you have more control there is inherently less risk. The more control you have the more actionable choices you can make to produce desirable, or comfortable, outcomes.",
      5: "Here lies the two other points that I conveniently skipped over. Control is deceptive and self-serving. See control, the more it grows, the more it becomes a mindset of, “how can I get the desired outcome I want.” That, inherently, will become self-serving at some point. See what control neglects to tell us, as Christians, is that it does not truly help us. See control is the ibuprofen when we have a headache for the brain tumor. Sure, it can help. It will not ever solve the issues of pride and self-indulgence. Two vices that stand directly in the way of ourselves and Christ. See, the issue of our salvation is solved when we let go of control and self indulgence. When we say, “Jesus, I need you to take control of my life.” That is when He will start to move radically. See control inherently takes away some of the greatest things God and this world have to offer. For one, true love does not involve controlling another. A relationship where one person is controlling another is quite frankly the inverse of love all together. Now a love, in which both choose to love the other person no matter what. Wow, that is beautiful. It is difficult to not want to control the situation. That cannot be understated, but it is all the more worth it in the end.",
    },
  },
  {
    name: "Choosing Joy",
    image: image,
    type: "personal writings",
    date_published: "2/4/2024",
    paragraphs: {
      1: "Patience with God does not exist. Patience with ourselves is a lost art. There is a reason that this conversation is needed so badly in society. We all have our own timeline of when our desires should come to fruition. Our Own timeline is our expectation. Our expectations create momentary fulfillment or potentially lasting disappointment. This is a life struggle for all. However, there is something greater and more fulfilling. It rivals the power of unmet expectations and unsustainable momentary fulfillment. That is the act of “Choosing joy”. In this day and age, we think the act of “choosing joy” is as simple as grabbing an apple from the fridge and eating it. In reality, we are forgetting the fact that the apple came from a tree that took years to grow, endless hours of dedicated nourishment, and a myriad of nutrients all to produce the ripe fruit.",
      2: "First and foremost, the seed of “choosing joy” needs to be planted. As a near and dear friend of mine would say, “The best time to plant a tree was 20 years ago; the next best time is now.” So, come on… Start. This looks like aligning our “wholeness” to God. Understanding the numerous blessings that you have received. Even in the most extreme cases of hurt, poverty, loss, grief, agony it is encouraged and recommended not to dwell on the negatives, but to find the good, which is difficult. Do not hear me wrong, but I never said planting a tree was the same for everyone. In grief, It is encouraged to look at the good and blessed times you had with the person or thing lost rather than reminisce on the fact that you won't ever have it again. Now as the pattern of nurturing the tree grows. The daily liturgies of praise, the recognition of the blessings one has or had, the gratitude expressed in it all, and the ability to look at any situation as an opportunity, blessing, and chance to grow then you will come to find that it is no longer a chore to do said tasks, but it in fact produces fruit and will be easy. It is then that “choosing joy” starts to come to fruition. Choosing joy has never been a single choice. It has been a repeated commitment of expressing our praise for what God has done and is doing. It is a continual act of love towards God, others, and ourselves. See, the fruit we produce with joy is not just for ourselves. Soon we will find that the fruits produced become an offering to God, sustenance for others in need, and fulfillment for ourselves. Some of us feel as if we are planting a tree in a barren wasteland, and it feels hopeless to find joy in. In that I’d say still try. God does amazing things. And those who have luscious gardens to plant a tree in, help a fellow believer out, you’ve been blessed with a rich opportunity don’t put it to waste. Start now. Choose joy.",
    },
  },
  {
    name: "The Banshees of Inisherin | Reflection on Loneliness and Kindess",
    image: image,
    type: "movies",
    date_published: "10/1/2024",
    paragraphs: [
      `I recently watched a movie called *The Banshees of Inisherin*, which masterfully described the importance of kindness and the danger of loneliness and questioned the purpose of life. A small town setting set in 1926 post-revolution, yet still lingering war Ireland. The beautiful landscapes never grew dull and continued to amaze as the movie progressed. `,
      `The movie takes action as the two main characters, Pádraic Súilleabháin and Colm Doherty, have a falling out for a reason initially unknown to the viewer. However, to the bartender and townsfolk, the relationship had always seemed odd as Pádraic was a “nice” fellow, always kind, whereas Colm was a “thinker.” Pádraic, taken aback by the sudden distance Colm had created, does everything that he can to “win” back Colm. On the other hand, Colm plans to separate himself from his dull ex-friend. Colm wanted something more from life that Pádraic could not bring to the table; he wanted to be remembered for his art. He would begin to work with the town and eventually be a musical tutor, doing all he could to leave his musical imprint on the world.`,
      `Through repeated attempts by Pádraic to reestablish their relationship, Colm stood his ground but eventually threatened Pádraic, saying that if he interacted with him again, he would cut one of his own fingers off one at a time until he had no more left. Pádraic, unsure if the request was true, would question Colm’s sanity.`,
      `Now, as was culturally appropriate at the time, the town would go to church on Sunday where a confessional would reveal the former, and maybe the still current, state of Colm’s mental health when the priest would ask, “How’s the despair?” Colm insists that he is doing fine. However, the reality of that may not be entirely accurate.`,
      `Throughout the story, a young man named Dominic Kearney enters the scene. While he is the essence of the town idiot trope, he always seems to be incredibly fond of Pádraic and especially his sister Siobhán Súilleabháin. Through a sudden encounter with Dominic, it is revealed that he is being beaten by his father (the town’s policeman). Dominic also finds himself at odds with everyone else as the bar bans him from coming in, the nearby town shop unlikes him, and Siobhán loathes him. Yet, Pádraic still shows him friendship.`,
      `As the story goes on, the reality that Colm does not want to be friends anymore with Pádraic begins to settle in. Pádraic starts to lash out and act maliciously to regain their friendship. Eventually, we come to the [scene](https://youtu.be/3fEvZ_kbshQ?feature=shared) where Pádraic, drunk, sees Colm extend friendship to Dominic’s abusive father. In his attempt to “stand up” for himself, he confronts the father, announcing to the world what sins the father has committed. How, then, does Colm respond? By telling Pádraic that he needs to leave. In an outrage, Pádraic, now permitted to speak to Colm, says, “Colm used to be nice.” This is the thesis of the movie. What matters most in life? How nice you are, or what impact can be remembered?`,
      `Pádraic begins to lose his way. He is tired of his “niceness” enabling the breakup of him and his best friend. While riding home, he sees one of Colm’s students and tricks and manipulates him into returning home. While this does nothing to impact Pádraic and Colm, its impact shatters the reality of the lonely Dominic. Later, Dominic and Pádraic are sitting together when Dominic advises that Pádraic needs to stand up for himself more. Pádraic takes it to heart but confuses being rude and “standing up for oneself” to mean the same. Pádraic unapologetically tells the story of sending Colm’s student back home. This is when Dominic loses all faith in humanity—claiming that he is no longer nice. Dominic sees him just like everyone else: [mean](https://www.youtube.com/watch?v=1VGQn7eiQPs).`,
      `It's a crisp afternoon, and Siobhán is overlooking the lake when Dominic comes to find her. Stumbling over his words, Dominic proposes to her, saying, “Is there a chance that a girl like you would fall in love with a boy like me?” Siobhán politely responds with a no, but Dominic, fully knowing this would be the answer, doubles down, asking, “Not even when I get to your age?”. Siobhán, once again, in a sweet, comforting manner, reaffirms her answer. Dominic responds with the gut-wrenching words, [“...well, there goes that dream, I guess.”](https://www.youtube.com/watch?v=N6oF9OpmjGw) This was Dominic’s Hail Mary. He lost everyone when he lost one. Just like Dominic’s now-lost friend, he was alone and desperately trying to find a connection. Like Colm and Pádraic, who lost their ways of curing their despair and loneliness, Dominic would lose his will to live. The town’s old lady would bring the father to find Dominic washed up on the shore after supposedly killing himself.`,
      `What’s the point of this almost plotless movie? I don’t think it is meant to be a depressing saga of two friends losing their way but a calling for each individual's weight on the world around them. Our decisions matter. So, is kindness more important than the lasting impact we can make on the world? Yes, the art that can come from others is beautiful, but it means very little if someone is alone and doesn’t feel like others are nice to them. Niceness comes in different forms. I think Colm would feel as if Pádraic neglected some of his artistic desires for the sake of daily pub hangouts. Pádraic could have been nicer by enabling him to succeed in his earlier endeavors so that Colm wouldn’t have had to put his foot down on the relationship so much.`,
      `I do not have a neat conclusion for this story other than it had me thinking about how others perceive life. For some, being “nice” means enjoying the monotonous lifestyle; for others, it means enabling them to pursue greater aspirations; for some, the one ounce of kindness gets them out of bed every morning. But, in reality, the movie does an excellent job of addressing the complete picture that different people have different desires with how they want to receive love.`,
    ],
  },
];
