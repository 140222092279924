import { Link } from "react-router-dom";
import back from "../assets/small_river_NW.jpg";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${back})` }}>
      <div className="headerWrapper">
        <div className="headerContainer">
          <h1>Pay Forward</h1>
          <p>1 John 4:10-11</p>
          <p className="verse">
            "In this is love, not that we have loved God but that he{" "}
            <strong>loved us</strong> and sent his Son to be the{" "}
            <em>propitiation</em> for our sins. Beloved, if God so loved us, we
            also{" "}
            <u>
              <strong>ought</strong>
            </u>{" "}
            to love one another."
          </p>
          <div className="buttonContainer">
            <Link to="/writings">
              <button>Writings</button>
            </Link>
            <Link to="/about">
              <button>About</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
